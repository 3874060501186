const routes = [{
  path: '/',
  redirect: '/login',
  component: () => import('@/views/Index'),
  children: [{
    path: '/project',
    meta: {
      title: '项目列表'
    },
    name: 'Project',
    component: () => import( /* webpackChunkName: "Project" */'../views/Project')
  }, {
    path: '/project/add',
    name: 'addProject',
    meta: {
      title: '项目录入'
    },
    component: () => import( /* webpackChunkName: "addProject" */'../views/Project/Add')
  }, {
    path: '/project/modify',
    name: 'modifyProject',
    meta: {
      title: '项目编辑'
    },
    component: () => import( /* webpackChunkName: "modifyProject" */'../views/Project/Modify')
  }, {
    path: '/project/detl',
    name: 'ProjectDetl',
    meta: {
      title: '项目详情'
    },
    component: () => import( /* webpackChunkName: "ProjectDetl" */'../views/Project/Detl')
  }, {
    path: '/building',
    meta: {
      title: '楼盘字典'
    },
    name: 'Building',
    component: () => import( /* webpackChunkName: "Building" */'../views/Building')
  }, {
    path: '/building/add',
    name: 'AddBuilding',
    meta: {
      title: '录入楼盘'
    },
    component: () => import( /* webpackChunkName: "AddBuilding" */'../views/Building/Operate/add')
  }, {
    path: '/building/modify',
    name: 'modifyBuilding',
    meta: {
      title: '编辑楼盘'
    },
    component: () => import( /* webpackChunkName: "modifyBuilding" */'../views/Building/Operate/modify')
  }, {
    path: '/building/detl',
    name: 'BuildingDetl',
    meta: {
      title: '楼盘详情'
    },
    component: () => import( /* webpackChunkName: "BuildingDetl" */'../views/Building/Detl')
  }, {
    path: '/property',
    name: 'Property',
    meta: {
      title: '房源管理'
    },
    component: () => import( /* webpackChunkName: "Property" */'../views/Property'),
    children: [{
      path: '/property/rent',
      name: 'PropertyRent',
      meta: {
        title: '租赁房源'
      },
      component: () => import( /* webpackChunkName: "PropertyRent" */'../views/Property/Rent')
    }, {
      path: '/property/sell',
      name: 'PropertySell',
      meta: {
        title: '买卖房源'
      },
      component: () => import( /* webpackChunkName: "PropertySell" */'../views/Property/Sell')
    }, {
      path: '/property/lapse',
      name: 'PropertyLapse',
      meta: {
        title: '无效房源'
      },
      component: () => import( /* webpackChunkName: "PropertyLapse" */'../views/Property/Lapse')
    }, {
      path: '/property/graph',
      name: 'PropertyGraph',
      meta: {
        title: '图形房源'
      },
      component: () => import( /* webpackChunkName: "PropertyGraph" */'../views/Property/Graph')
    }]
  }, {
    path: '/property/detl',
    name: 'PropertyDetl',
    meta: {
      title: '房源详情'
    },
    component: () => import( /* webpackChunkName: "PropertyDetl" */'../views/Property/Detl')
  }, {
    path: '/property/modify',
    name: 'PropertyModify',
    meta: {
      title: '房源编辑'
    },
    component: () => import( /* webpackChunkName: "PropertyModify" */'../views/Property/Modify')
  }, {
    path: '/property/add',
    name: 'PropertyAdd',
    meta: {
      title: '录入房源'
    },
    component: () => import( /* webpackChunkName: "PropertyAdd" */'../views/Property/Add')
  }, {
    path: '/customer',
    name: 'Customer',
    meta: {
      title: '客源管理'
    },
    component: () => import( /* webpackChunkName: "Customer" */'../views/Customer')
  }, {
    path: '/owner',
    name: 'Owner',
    meta: {
      title: '业主管理'
    },
    component: () => import( /* webpackChunkName: "Owner" */'../views/Owner')
  }, {
    path: '/activity/ownerSelf',
    name: 'Activity',
    meta: {
      title: '活动管理'
    },
    component: () => import( /* webpackChunkName: "Activity" */'../views/Activity'),
    children: [{
      path: '/activity/ownerSelf',
      name: 'activityOwnerSelf',
      meta: {
        title: '业主活动'
      },
      component: () => import( /* webpackChunkName: "Activity" */'../views/Activity/OwnerSelf')
    }, {
      path: '/activity/platForm',
      name: 'activityPlatForm',
      meta: {
        title: '平台活动'
      },
      component: () => import( /* webpackChunkName: "Activity" */'../views/Activity/PlatForm')
    }]
  }, {
    path: '/member',
    name: 'Member',
    meta: {
      title: '会员管理'
    },
    component: () => import( /* webpackChunkName: "Member" */'../views/Member'),
    children: [{
      path: '/member/list',
      name: 'memberList',
      meta: {
        title: '会员列表'
      },
      component: () => import( /* webpackChunkName: "Member" */'../views/Member/List')
    }, {
      path: '/member/invite',
      name: 'memberInvite',
      meta: {
        title: '会员管理'
      },
      component: () => import( /* webpackChunkName: "Member" */'../views/Member/Invite')
    }]
  }, {
    path: '/audit',
    name: 'audit',
    meta: {
      title: '审核管理'
    },
    component: () => import( /* webpackChunkName: "Audit" */'../views/Audit'),
    children: [{
      path: '/audit/wait',
      name: 'AuditWait',
      meta: {
        title: '待审核'
      },
      component: () => import( /* webpackChunkName: "Audit" */'../views/Audit/Wait')
    }, {
      path: '/audit/finish',
      name: 'AuditFinish',
      meta: {
        title: '已审核'
      },
      component: () => import( /* webpackChunkName: "Audit" */'../views/Audit/Finish')
    }, {
      path: '/audit/delist',
      name: 'AuditDelist',
      meta: {
        title: '到期下架'
      },
      component: () => import( /* webpackChunkName: "Audit" */'../views/Audit/Delist')
    }, {
      path: '/audit/updateRecord',
      name: 'AuditUpdateRecord',
      meta: {
        title: '更新记录'
      },
      component: () => import( /* webpackChunkName: "Audit" */'../views/Audit/UpdateRecord')
    }]
  }, {
    path: '/feedback',
    name: 'Feedback',
    component: () => import( /* webpackChunkName: "Feedback" */'../views/Feedback'),
    children: [{
      path: '/feedback/list',
      name: 'feedbackList',
      meta: {
        title: '联系会员反馈'
      },
      component: () => import( /* webpackChunkName: "Feedback" */'../views/Feedback/List')
    }, {
      path: '/feedback/deleted',
      name: 'feedbackDeleted',
      meta: {
        title: '回收站'
      },
      component: () => import( /* webpackChunkName: "Feedback" */'../views/Feedback/Deleted')
    }]
  }]
}, {
  path: '/login',
  name: 'Login',
  component: () => import( /* webpackChunkName: "Login" */'../views/Login')
}, {
  path: '/map',
  name: 'Map',
  component: () => import( /* webpackChunkName: "Test" */'../views/Map')
}];
export default routes;